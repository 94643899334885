import React, { useEffect, useRef, useState } from 'react';
import { getImgCropShopify, getModelUrl, loaded } from '../../utils/helpers';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import ProductPriceLive from '../common/ProductPriceLive';

const CustomLink = loadable(() => import('../../components/common/CustomLink'));
const ReactSlick = loadable(() => import('./../common/ReactSlick'));

const SliderProductNew = React.memo((props) => {
  const box = useRef(null);
  const data = props.data.map((v) => v.product);
  const [state, setState] = useState(false);
  const len = data.length;
  const isMobile = loaded && window.innerWidth < 992;
  const isPC = loaded && window.innerWidth >= 992;

  const settings = {
    slidesToShow: 5,
    infinite: len > 5,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          infinite: len > 4,
          lazyLoad: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          infinite: len > 3,
          lazyLoad: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          infinite: len > 1,
          centerMode: true,
          lazyLoad: true,
        },
      },
    ],
  };

  function handleSlickGoTo(next = true) {
    if (box.current) {
      next ? box.current.slickNext() : box.current.slickPrev();
    }
  }

  const checkShowArrows = () => {
    if (loaded) {
      const width = window?.innerWidth;
      const len = data?.length;
      if (width < 768 && len >= 2) {
        !state && setState(2);
        return true;
      } else if (width < 992 && len > 3) {
        !state && setState(3);
        return true;
      } else if (width < 1200 && len > 4) {
        !state && setState(4);
      } else if (len > 5) {
        !state && setState(true);
        return true;
      } else {
        !state && setState(true);
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    checkShowArrows();
  }, []);

  return (
    <div
      className="main__product-new container-xl"
      onmouseover={() => (!state || Number.isInteger(state)) && setState(true)}
    >
      <div className="main__product-new__title -title --arrows">
        <CustomLink link={props?.url} text={<h2>{props?.title}</h2>} />

        {state && (
          <div className="arrows">
            <span className="arrows__btn --left" onClick={() => handleSlickGoTo(false)}></span>
            <span className="arrows__btn --right" onClick={() => handleSlickGoTo()}></span>
          </div>
        )}
      </div>
      <div className="main__product-new__owl-carousel">
        <ReactSlick ref={box} {...settings}>
          {data.map((v, i) => (
            <div key={i} className="product">
              {(state === true || state > i) && v?.image && (
                <figure className="product--image">
                  <Link
                    className="--overlay"
                    to={getModelUrl('buy', v?.handle?.replace('buy-', ''))}
                    title={v?.title}
                  >
                    <img
                      width={267}
                      height={267}
                      src={getImgCropShopify(v?.image?.src, '267x267')}
                      alt={v?.title}
                    />
                  </Link>

                  {isPC && v?.images?.length > 1 && (
                    <Link
                      className="image--hover"
                      to={getModelUrl('buy', v?.handle.replace('buy-', ''))}
                      title={v?.title}
                    >
                      <img
                        width={267}
                        height={267}
                        src={getImgCropShopify(v?.images[1]?.src, '267x267')}
                        alt={v?.title}
                      />
                    </Link>
                  )}
                </figure>
              )}
              <p className="product--brand">
                <Link to={getModelUrl('brands', v?.vendor, true)}>{v?.vendor}</Link>
              </p>
              <p className="product--name">
                <Link to={getModelUrl('buy', v?.handle?.replace('buy-', ''))}>
                  {v?.title.replace(v?.vendor || '', '')}
                </Link>
              </p>
              <ProductPriceLive product={v} />
              <p className="product--link">
                <Link to={getModelUrl('buy', v?.handle?.replace('buy-', ''))}>Shop This</Link>
              </p>
            </div>
          ))}
        </ReactSlick>
      </div>
    </div>
  );
});

export default SliderProductNew;
